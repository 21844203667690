import React from "react";

import Header from '../components/Header/Header';
import HeroTheLegend from '../components/HeroTheLegend/HeroTheLegend';

const NotFoundPage = () => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
    >
      <Header />
      <HeroTheLegend title="Page not found" isNotFound />
    </div>
  )
}

export default NotFoundPage
